import React from "react";
import "./App.css";
import logo from "./images/logo.png";

function App() {
  var Airtable = require("airtable");
  var base = new Airtable({ apiKey: process.env.REACT_APP_API }).base(
    "appL3M9G9pqa9fjqg"
  );

  function adapt() {
    switch (document.getElementById("cours").value) {
      case "Sport":
        document.getElementById("block_ffsu").style.display = "block";
        document.getElementById("block_note").style.display = "none";
        document.getElementById("block_nom_cours").style.display = "none";
        document.getElementById("block_message").innerText = "";
        break;
      case "Soft Skills":
        document.getElementById("block_ffsu").style.display = "none";
        document.getElementById("block_note").style.display = "none";
        document.getElementById("block_nom_cours").style.display = "none";
        document.getElementById("block_message").innerText =
          "Oups, les Soft Skills sont non excusables...";
        break;
      case "Autre":
        document.getElementById("block_ffsu").style.display = "none";
        document.getElementById("block_note").style.display = "block";
        document.getElementById("block_nom_cours").style.display = "block";
        document.getElementById("block_message").innerText = "";
        break;
      default:
        document.getElementById("block_ffsu").style.display = "none";
        document.getElementById("block_note").style.display = "block";
        document.getElementById("block_nom_cours").style.display = "none";
        document.getElementById("block_message").innerText = "";
        break;
    }
  }

  function adapt2(id) {
    switch (document.getElementById(id).value) {
      case "Oui":
        document.getElementById("block_message2").innerText =
          "Ton absence ne peut malheureusement pas être excusée :/";
        break;
      case "Non":
      default:
        document.getElementById("block_message2").innerText = "";
        break;
    }
  }

  function createAbsence() {
    var nom = document.getElementById("nom").value.toUpperCase();
    var prenom = document.getElementById("prenom").value;
    prenom = prenom[0].toUpperCase() + prenom.substring(1);
    var motif = document.getElementById("motif").value;
    var cours = document.getElementById("cours").value;
    var nom_cours = document.getElementById("nom_cours").value;
    var note = document.getElementById("note").value;
    var ffsu = document.getElementById("ffsu").value;
    var date = document.getElementById("date").value;
    var duree = document.getElementById("duree").value;
    var message = document.getElementById("message").value;

    if (
      nom !== "" &&
      prenom !== "" &&
      motif !== "" &&
      cours !== "" &&
      date !== "" &&
      duree !== ""
    ) {
      const remplissage_date = new Date();

      let day = remplissage_date.getDate();
      let month = remplissage_date.getMonth() + 1;
      let year = remplissage_date.getFullYear();

      console.log(base.getId());

      base("Tasks").create(
        [
          {
            fields: {
              nom: nom,
              prenom: prenom,
              done: false,
              motif: motif,
              cours: cours,
              nom_cours: nom_cours,
              date: date,
              ffsu: ffsu,
              note: note,
              duree: duree,
              message: message,
              date_remplissage: `${year}-${month}-${day}`,
            },
          },
        ],
        function (err, records) {
          if (err) {
            console.error("Erreur1 : ", err);
            document.getElementById("forms").style.display = "none";
            document.getElementById("reponse").textContent =
              "Erreur lors de l'enregistrement...";
            return;
          }
          console.log(records);
        }
      );
      document.getElementById("forms").style.display = "none";
      document.getElementById("gif").style.display = "block";
      document.getElementById("reponse").textContent =
        "Ton absence a bien été enregistrée !";
    } else {
      console.error("Erreur2");
      document.getElementById("forms").style.display = "none";
      document.getElementById("reponse").textContent =
        "Erreur lors de l'enregistrement...";
    }

    window.scrollTo(0, 0);

    setTimeout(() => {
      window.location.reload(1);
    }, 5000);
  }

  return (
    <div className="App">
      <img src={logo} alt="logo_ved"></img>

      <div id="gif"></div>

      <h1>Formulaire d'absences</h1>

      <h2 id="reponse"> </h2>

      <div className="form" id="forms">
        <p>
          <input id="nom"></input>
          <label>Nom</label>
        </p>
        <p>
          <input id="prenom"></input>
          <label>Prénom</label>
        </p>
        <p>
          <input id="motif"></input>
          <label>Motif de l'absence</label>
        </p>
        <p>
          <select id="cours" onChange={adapt}>
            <option selected disabled></option>
            <option>Langue Vivante</option>
            <option>Soft Skills</option>
            <option>Sport</option>
            <option>Autre</option>
          </select>
          <label>Type de cours</label>
        </p>
        <p id="block_message"></p>
        <p id="block_nom_cours">
          <input id="nom_cours"></input>
          <label>Nom du cours</label>
        </p>
        <p id="block_note">
          <select id="note" onChange={() => adapt2("note")}>
            <option selected disabled></option>
            <option>Oui</option>
            <option>Non</option>
          </select>
          <label>Cours noté ?</label>
        </p>
        <p id="block_ffsu">
          <select id="ffsu" onChange={() => adapt2("ffsu")}>
            <option selected disabled></option>
            <option>Oui</option>
            <option>Non</option>
          </select>
          <label>Sport FFSU ?</label>
        </p>
        <p id="block_message2"></p>
        <p>
          <input id="date" type="date"></input>
          <label>Date</label>
        </p>
        <p>
          <select id="duree">
            <option selected disabled></option>
            <option>Matin</option>
            <option>Aprem</option>
            <option>Journee</option>
          </select>
          <label>Durée de l'absence</label>
        </p>
        <p>
          <textarea id="message"></textarea>
          <label>Quelque chose à rajouter ?</label>
        </p>
        <p id="btn">
          <button className="btn-admin" onClick={createAbsence}>
            Envoyer
          </button>
        </p>
      </div>
      <p id="mypart">
        Made with ♥ by{" "}
        <a href="https://linktr.ee/snydrive" target="_blank" rel="noreferrer">
          SnyDrive
        </a>
      </p>
    </div>
  );
}

export default App;
